import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import userStoreModule from '../userStoreModule';

export default function useVerifyPhone(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  const toastification = toast();
  const blankItem = {
    phone: props.userData.phone,
    pin: '',
  };
  const isSubmitting = ref(false);
  const refModalVerifyPhone = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const requestMissCallOTP = () => {
    store.dispatch('user/requestVerifyPhoneOtp', { phone: props.userData.phone })
      .then(() => {
        toastification.showToastSuccess('Vui lòng kiểm tra điện thoại, sẽ có số điện thoại gọi đến. Hãy lấy 4 số cuối của số điện thoại gọi đến làm mã OTP');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      const data = {
        pin: itemLocal.value.pin,
        phone: props.userData.phone,
      };

      isSubmitting.value = true;
      store
        .dispatch('user/verifyPhone', data)
        .then(() => { emit('on-item-updated'); })
        .then(() => {
          refModalVerifyPhone.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } catch (error) {

    }
  };

  return {
    refModalVerifyPhone,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    requestMissCallOTP,
  };
}
