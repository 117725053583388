import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString, updateUserData } from '@/auth/utils';
import userStoreModule from '../userStoreModule';

export default function useProfileUpdateModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const avatarChanged = file => {
    itemLocal.value.avatarFile = file;
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      const formData = new FormData();

      formData.append('name', itemLocal.value.name || '');
      formData.append('phone', itemLocal.value.phone || '');
      formData.append('email', itemLocal.value.email || '');
      formData.append('idNumber', itemLocal.value.idNumber || '');
      formData.append('placeOfIssue', itemLocal.value.placeOfIssue || '');
      formData.append('address', itemLocal.value.address || '');

      if (itemLocal.value.province && itemLocal.value.province.id > 0) {
        formData.append('provinceId', itemLocal.value.province.id);
      }
      if (itemLocal.value.district && itemLocal.value.district.id > 0) {
        formData.append('districtId', itemLocal.value.district.id);
      }
      if (itemLocal.value.ward && itemLocal.value.ward.id > 0) {
        formData.append('wardId', itemLocal.value.ward.id);
      }

      if (itemLocal.value.gender) {
        formData.append('gender', itemLocal.value.gender.id);
      }

      formData.append('birthday', parseDateToString(itemLocal.value.birthday));
      formData.append('issueDate', parseDateToString(itemLocal.value.issueDate));

      if (itemLocal.value.avatarFile) {
        formData.append('avatar', itemLocal.value.avatarFile);
      }

      isSubmitting.value = true;
      store
        .dispatch('user/updateProfile', formData)
        .then(response => {
          updateUserData(response.data);
          emit('on-item-updated', response.data);
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } catch (error) {

    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    avatarChanged,
  };
}
