<template>
  <b-modal
    id="modal-verify-phone"
    ref="refModalVerifyPhone"
    title="Xác thực số điện thoại"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <span>Vui lòng nhập <strong>4 số cuối</strong> của số điện thoại gọi đến cho bạn. Nếu bạn không nhận được cuộc gọi đến trong vòng 1 phút, hãy ấn <b-link @click="requestMissCallOTP">
          Gửi lại
        </b-link></span>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Mã OTP (4 số cuối của SĐT gọi đến)"
            >
              <template
                v-slot:label
              >
                Mã OTP
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="pin"
                rules="required"
              >
                <b-form-input
                  id="forgot-password-phone"
                  v-model="itemLocal.pin"
                  :state="errors.length > 0 ? false : null"
                  name="pin"
                  placeholder="6886"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BLink,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useVerifyPhone from './useVerifyPhone';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BLink,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },

  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalVerifyPhone,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      avatarChanged,

      onSubmit,
      requestMissCallOTP,
    } = useVerifyPhone(props, emit, refFormObserver);

    return {
      refModalVerifyPhone,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      requestMissCallOTP,
      avatarChanged,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
